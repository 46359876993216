import CtaWidget from './CtaWidget';
import CoversWidget from './CoversWidget';
import SpotlightWidget from './SpotlightWidget';
import ThumbsWidget from './ThumbsWidget';
import CardsWidget from './CardsWidget';
import StatsWidget from './StatsWidget';
import FeaturesWidget from './FeaturesWidget';
import ProjectPreview from './ProjectPreview';
import SectionTitleWidget from './SectionTitleWidget';
import SingleImageWidget from './SingleImageWidget';
import TextBoxWidget from './TextBoxWidget';
import VideoWidget from './VideoWidget';
import LeadImageWidget from './LeadImageWidget';
import NavigationWidget from './NavigationWidget';
import PageTitleWidget from './PageTitleWidget';
import ArticleTitleWidget from './ArticleTitleWidget';
import QuoteWidget from './QuoteWidget';
import BeforeAfterWidget from './BeforeAfterWidget';
import SingleImageSliderWidget from './SingleImageSliderWidget';
import LargeSliderWidget from './LargeSliderWidget';
import PaSectionWidget from './PaSectionWidget';
import RaSectionWidget from './RaSectionWidget';
import LlSectionWidget from './LlSectionWidget';
import AxSectionWidget from './AxSectionWidget';
import HtmlWidget from './HtmlWidget';
import ProjectPreviewPlain from './ProjectPreviewPlain';

export default {
  CtaWidget,
  QuoteWidget,
  CoversWidget,
  SpotlightWidget,
  ThumbsWidget,
  CardsWidget,
  StatsWidget,
  FeaturesWidget,
  ProjectPreview,
  SectionTitleWidget,
  SingleImageWidget,
  TextBoxWidget,
  VideoWidget,
  LeadImageWidget,
  NavigationWidget,
  PageTitleWidget,
  ArticleTitleWidget,
  BeforeAfterWidget,
  SingleImageSliderWidget,
  LargeSliderWidget,
  PaSectionWidget,
  RaSectionWidget,
  LlSectionWidget,
  AxSectionWidget,
  HtmlWidget,
  ProjectPreviewPlain,
};
