import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';

const ArticleTitleWidget = ({ title, subTitle, date, author, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="container">
        <div className="article-title">
          <h1>{title}</h1>
          {subTitle ? <h2>{subTitle}</h2> : null}
          <div className="meta">{date} | {author}</div>
        </div>
      </div>
    </div>
  </Container>
);

ArticleTitleWidget.title = 'Article Title';
ArticleTitleWidget.category = 'General';

ArticleTitleWidget.defaultProps = {
  subTitle: '',
};

ArticleTitleWidget.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  date: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

ArticleTitleWidget.defaults = {
  title: 'Lorem ipsum dolor sit amet, consectetur adipisicing',
  subTitle: 'Why some sub-titles work, while others look silly',
  date: '27 Oct 2017',
  author: 'John Doe',
  containerSettings: {},
};

ArticleTitleWidget.adminFields = {
  title: { type: 'text' },
  subTitle: { type: 'text' },
  date: { type: 'text' },
  author: { type: 'text' },
};

export default ArticleTitleWidget;
