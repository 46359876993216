import React from 'react';
import PropTypes from 'prop-types';
import { Parallax } from 'react-scroll-parallax';
import { Container, Img } from '@raketa-cms/raketa-cms';

import ParallaxImage from '../ParallaxImage';

const LeadImageWidget = ({
  variant,
  title,
  subTitle,
  image,
  overlay,
  btnPrimaryLabel,
  btnPrimaryLink,
  btnSecondaryLabel,
  btnSecondaryLink,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className={`lead-image ${variant === 'full' ? 'full' : ''}`}>
      <div className="overlay" style={{ backgroundColor: `rgba(0, 0, 0, ${overlay})` }} ></div>
      <Parallax
        className="lead-image-background"
        offsetYMin={-50}
        offsetYMax={50}
        slowerScrollRate
        tag="figure"
      >
        <ParallaxImage src={image} variant="lead" />
        <ParallaxImage src={image} variant="parallax_portrait_left" />
      </Parallax>

      <div className="lead-image-content-wrapper">
        <div className="lead-image-content">
          <h1>{title}</h1>
          {subTitle &&
            <div className="grid">
              <div className="shift-2">
                <p>{subTitle}</p>
              </div>
            </div>
          }

          <div className="cta-wrapper">
            {(btnPrimaryLabel && btnPrimaryLink) &&
              <a href={btnPrimaryLink} className="btn-primary">{btnPrimaryLabel}</a>
            }
            {(btnSecondaryLink && btnSecondaryLabel) &&
              <a href={btnSecondaryLink} className="btn-secondary invert">{btnSecondaryLabel}</a>
            }
          </div>
        </div>
      </div>
    </div>
  </Container>
);

LeadImageWidget.title = 'Lead Image';
LeadImageWidget.category = 'Highlights';

LeadImageWidget.propTypes = {
  image: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  btnPrimaryLabel: PropTypes.string.isRequired,
  btnPrimaryLink: PropTypes.string.isRequired,
  btnSecondaryLabel: PropTypes.string.isRequired,
  btnSecondaryLink: PropTypes.string.isRequired,
  overlay: PropTypes.number,
  variant: PropTypes.string,
  containerSettings: PropTypes.object.isRequired,
};

LeadImageWidget.defaults = {
  variant: 'normal',
  title: 'Lead image title',
  subTitle: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
  btnPrimaryLabel: 'Call to action',
  btnPrimaryLink: '#',
  btnSecondaryLabel: 'Call to action',
  btnSecondaryLink: '#',
  image: 'http://placehold.it/1920x1080',
  overlay: 0.2,
  containerSettings: {},
};

LeadImageWidget.adminFields = {
  variant: { type: 'select', options: [['normal', 'Normal'], ['full', 'Full Height']] },
  overlay: { type: 'select', options: [[0, 'No Overlay'], [0.1, '10%'], [0.2, '20%'], [0.3, '30%'], [0.4, '40%'], [0.5, '50%'], [0.6, '60%'], [0.7, '70%'], [0.8, '80%']] },
  image: { type: 'image' },
  title: { type: 'text' },
  subTitle: { type: 'text' },
  btnPrimaryLabel: { type: 'text' },
  btnPrimaryLink: { type: 'text' },
  btnSecondaryLabel: { type: 'text' },
  btnSecondaryLink: { type: 'text' },
};

export default LeadImageWidget;
