import React from 'react';

class SiteHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavOpen: false,
    }
  }

  componentDidMount() {
    let lastScrollPosition = 0;

    window.addEventListener('scroll', (e)=> {
      let newScrollPosition = window.scrollY;

      if (newScrollPosition < lastScrollPosition) {
        this.header.classList.remove('header-hide');
        this.header.classList.add('header-show');
      } else {
        this.header.classList.remove('header-show');
        this.header.classList.add('header-hide');
      }

      if (newScrollPosition <= this.header.offsetHeight) {
        this.header.classList.remove('header-hide');
        this.header.classList.remove('header-show');
      }

      lastScrollPosition = newScrollPosition;
    });
  }

  toggleNav() {
    const { isNavOpen } = this.state;

    this.setState({ isNavOpen: !isNavOpen }, () => {
      if (this.state.isNavOpen) {
        document.querySelector('body').classList.add('fs-nav');
      } else {
        document.querySelector('body').classList.remove('fs-nav');
      }
    });
  }

  render() {
    const { logo, stickyLogo, menu, theme } = this.props;
    const { isNavOpen } = this.state;

    return (
      <header className={`site-header ${theme === 'light' ? 'header-light' : 'header-dark'}`} ref={header => { this.header = header }}>
        <a href="/landing" className="logo">
          <img src={logo} alt="" />
          <img src={stickyLogo} alt="" />
        </a>

        <nav className={isNavOpen ? 'show-nav' : ''}>
          {menu.items.map((item, idx) =>
            <React.Fragment>
              {item.subnav ?
                <span key={idx} className="link-item submenu-wrapper">
                  {item.label}

                  {item.subnav &&
                    <div className="subnav">
                      {item.subnav.split(';').map((sub, sidx) => <a href={sub.split('|')[0]} className="link-item" key={sidx}>{sub.split('|')[1]}</a>)}
                    </div>
                  }
                </span>
                :
                <a href={item.link} key={idx} className="link-item">{item.label}</a>
              }
            </React.Fragment>
          )}
        </nav>

        <div className={`menu-trigger ${isNavOpen ? 'close-trigger' : ''}`} onClick={() => this.toggleNav()}>
          <div className="trigger-item" />
          <div className="trigger-item" />
          <div className="trigger-item" />
        </div>
      </header>
    );
  }
}

export default SiteHeader;
