import React from 'react';
import jQuery from 'jquery';
import Modal from './Modal';

const TextField = ({ label, placeholder, error, type, name, value, onChange }) => (
  <div className={`form-group ${error ? 'has-error' : ''}`}>
    {label &&
      <label className="form-label">{label}</label>
    }
    <input type={type} name={name} placeholder={placeholder} value={value} onChange={e => onChange(e.target.value, e)} className="form-control" />
    {error &&
      <span className="error-text">{error}</span>
    }
  </div>
);

TextField.defaultProps = {
  type: 'text',
  placeholder: '',
};

const TextArea = ({ label, placeholder, error, type, name, value, onChange }) => (
  <div className={`form-group ${error ? 'has-error' : ''}`}>
    {label &&
      <label className="form-label">{label}</label>
    }
    <textarea name={name} placeholder={placeholder} value={value} onChange={e => onChange(e.target.value, e)} className="form-control" />
    {error &&
      <span className="error-text">{error}</span>
    }
  </div>
);

TextArea.defaultProps = {
  placeholder: '',
};

const Select = ({ label, placeholder, error, options, name, value, onChange }) => (
  <div className={`form-group ${error ? 'has-error' : ''}`}>
    {label &&
      <label className="form-label">{label}</label>
    }
    <select name={name} value={value} onChange={e => onChange(e.target.value, e)} className="form-control">
      {placeholder &&
        <option value="">{placeholder}</option>
      }

      {options.map((o, idx) =>
        <option key={idx} value={o[0]}>{o[1]}</option>)}
    </select>
    {error &&
      <span className="error-text">{error}</span>
    }
  </div>
);

Select.defaultProps = {
  placeholder: 'Please select...',
  options: [],
};

class ContactForm extends React.Component {
  state = {
    open: false,
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
    errors: {},
    sent: false,
  }

  handleSubmit(e) {
    e.preventDefault();

    const { name, email, phone, subject, message } = this.state;

    const errors = {};
    if (name.trim() === '') errors['name'] = 'Моля попълнете Вашето име';
    if (phone.trim() === '') errors['phone'] = 'Моля попълнете Вашия телефон';
    if (subject.trim() === '') errors['subject'] = 'Моля попълнете относно';
    if (message.trim() === '') errors['message'] = 'Моля попълнете съобщение';

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      const params = {
        contact: {
          name,
          email,
          phone,
          subject,
          message,
        }
      };

      jQuery.ajax({
        method: 'POST',
        beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content')); },
        contentType: 'application/json; charset=utf-8',
        url: '/contacts',
        data: JSON.stringify(params),
        dataType: 'json',
        success: (resp) => {
          this.setState({ sent: true })
        },
        error: (xhr, err, e) => {
          alert('An error occured sending this form. ');
        },
      });
    }
  }

  render() {
    const { open, errors, name, email, phone, subject, message, sent } = this.state;
    const { subjects } = this.props;

    return (
      <div className="contact-form">
        <button className="pulsating-circle" onClick={() => this.setState({ open: true })} />

        <Modal title="Контакти" open={open} onClose={() => this.setState({ open: false, sent: false })}>
          {sent &&
            <div className="form-wrapper">
              <p>Вашето запитване беше изпратено успешно!</p>
              <button type="button" className="btn-primary" onClick={() => this.setState({ open: false, sent: false })}>Затвори</button>
            </div>
          }
          {!sent &&
            <form className="form-wrapper" onSubmit={e => this.handleSubmit(e)}>
              <TextField label="Име *" name="contact[name]" error={'name' in errors ? errors['name'] : false} value={name} onChange={name => this.setState({ name })} />

              <div className="form-row">
                <div className="half">
                  <TextField label="Телефон *" name="contact[phone]" error={'phone' in errors ? errors['phone'] : false} value={phone} onChange={phone => this.setState({ phone })} />
                </div>
                <div className="half">
                  <TextField label="Ел. поща" name="contact[email]" error={'email' in errors ? errors['email'] : false} value={email} onChange={email => this.setState({ email })} />
                </div>
              </div>
              <Select label="Относно *" options={subjects.map(s => [s, s])} name="contact[subject]" error={'subject' in errors ? errors['subject'] : false} value={subject} onChange={subject => this.setState({ subject })} />
              <TextArea label="Съобщение *" name="contact[message]" error={'message' in errors ? errors['message'] : false} value={message} onChange={message => this.setState({ message })} />

              <button type="submit" className="btn-primary">Изпрати</button>
            </form>
          }
        </Modal>
      </div>
    );
  }
}

export default ContactForm;