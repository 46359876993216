import React from 'react';
import PropTypes from 'prop-types';
import { Container, Img, List, TextInput, ImagePicker } from '@raketa-cms/raketa-cms';

const ThumbsWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="covers">
      <div className="container">
        <div className="grid">
          {list.map((thumb, idx) => (
            <div className="third" key={idx}>
              <a href={thumb.link} className="cover">
                <Img src={thumb.image} alt="" className="mono" variant="square" />
                <div className="overlay"></div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

ThumbsWidget.title = 'Thumbs';
ThumbsWidget.category = 'Lists';

ThumbsWidget.propTypes = {
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

ThumbsWidget.defaults = {
  list: [
    { id: 1, image: 'http://via.placeholder.com/540x540', link: '#' },
    { id: 2, image: 'http://via.placeholder.com/540x540', link: '#' },
    { id: 3, image: 'http://via.placeholder.com/540x540', link: '#' }
  ],
  containerSettings: {},
};

const ThumbsItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      onChange={value => onChangeItem('image', value)}
      value={settings.image}
    />

    <TextInput
      label="Link"
      onChange={value => onChangeItem('link', value)}
      value={settings.link}
    />
  </div>
);

ThumbsItem.defaultProps = {
  settings: {},
};

ThumbsItem.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

ThumbsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) =>
        <ThumbsItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default ThumbsWidget;
