import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';

const PageTitleWidget = ({ title, subtitle, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="page-title">
      <div className="container">
        <h1>{title}</h1>
        {subtitle && <h2>{subtitle}</h2>}
      </div>
    </div>
  </Container>
);

PageTitleWidget.title = 'Page Title';
PageTitleWidget.category = 'General';

PageTitleWidget.defaultProps = {
  subtitle: '',
};

PageTitleWidget.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  containerSettings: PropTypes.object.isRequired,
};

PageTitleWidget.defaults = {
  title: 'Page Title',
  subtitle: 'Sub-title goes here',
  containerSettings: {},
};

PageTitleWidget.adminFields = {
  title: { type: 'text' },
  subtitle: { type: 'text' },
};

export default PageTitleWidget;
