import React from 'react';
import PropTypes from 'prop-types';
import { Container, Img, List, TextInput, ImagePicker, TextArea, SelectMenu } from '@raketa-cms/raketa-cms';

const CoversWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="covers">
      <div className="container">
        <div className="grid">
          {list.map((cover, idx) => (
            <div className="third" key={idx}>
              <a href={cover.link} className={`cover ${cover.position} ${cover.accent === 'accent' ? 'accent' : ''}`}>
                <Img src={cover.image} alt="" className="mono" variant="card_vertical" />
                <div className="cover-content">
                  <h3>{cover.title}</h3>
                  <p>{cover.description}</p>
                </div>
                <div className="overlay"></div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

CoversWidget.title = 'Covers';
CoversWidget.category = 'Lists';

CoversWidget.dialogSize = 'large';

CoversWidget.propTypes = {
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

CoversWidget.defaults = {
  list: [
    { id: 1, image: 'http://via.placeholder.com/350x500', title: 'Top title', description: 'Short description, or subline', linkLabel: 'Call to action', link: '#', accent: 'normal', position: 'top-left' },
    { id: 2, image: 'http://via.placeholder.com/350x500', title: 'Bottom title', description: 'Short description, or subline', linkLabel: 'Call to action', link: '#', accent: 'normal', position: 'bottom-left' },
    { id: 3, image: 'http://via.placeholder.com/350x500', title: 'Accented title', description: 'Short description, or subline', linkLabel: 'Call to action', link: '#', accent: 'accent', position: 'top-left' }
  ],
  containerSettings: {},
};

const CoversItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      onChange={value => onChangeItem('image', value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={value => onChangeItem('title', value)}
      value={settings.title}
    />

    <TextArea
      label="Description"
      onChange={value => onChangeItem('description', value)}
      value={settings.description}
    />

    <TextInput
      label="Link Label"
      onChange={value => onChangeItem('linkLabel', value)}
      value={settings.linkLabel}
    />

    <TextInput
      label="Link"
      onChange={value => onChangeItem('link', value)}
      value={settings.link}
    />

    <SelectMenu
      label="Variant"
      options={[['normal', 'Normal'], ['accent', 'Accent']]}
      value={settings.accent}
      onChange={value => onChangeItem('accent', value)}
    />

    <SelectMenu
      label="Position"
      options={[['top-left', 'Top Left'], ['bottom-left', 'Bottom Left']]}
      value={settings.position}
      onChange={value => onChangeItem('position', value)}
    />
  </div>
);

CoversItem.defaultProps = {
  settings: {},
};

CoversItem.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

CoversWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) =>
        <CoversItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default CoversWidget;
