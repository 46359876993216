import React from 'react';
import ContactForm from '../components/frontend/ContactForm';

export default ({ logo, menu, social_facebook, social_linkedin, social_instagram, contact_subjects }) => (
  <div className="site-footer">
    <div className="container">
      <div className="grid">
        <div className="full-width">
          <img src={logo} className="footer-logo" />

          <div className="footer-section">
            <a href={social_facebook} className="social-icon fb" />
            <a href={social_linkedin} className="social-icon linkedin" />
            <a href={social_instagram} className="social-icon instagram" />
          </div>
        </div>
      </div>

      <div className="grid">
        <div className="half">
          <nav className="footer-section">
            {menu.items.map((item, idx) =>
              <a href={item.link} key={idx}>{item.label}</a>
            )}
          </nav>
        </div>

        <div className="half">
          <div className="footer-section credits">
            launched by <a href="#">raketa</a>
          </div>
        </div>
      </div>
    </div>

    <ContactForm subjects={contact_subjects} />
  </div>
);
