import React from 'react';
import Swiper from 'react-id-swiper';
import { Img, imageSrc } from '@raketa-cms/raketa-cms';


class SwiperGallery extends React.Component {
  componentDidMount() {
    const { list, onChange } = this.props;
    if (onChange) this.swiper.on('slideChange', () => onChange(this.swiper, list));
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext()
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev()
  }

  render() {
    const { autoplay, zoom, list, initialSlide, onClose } = this.props;

    return (
      <div className={`gallery ${zoom ? 'has-zoom' : ''}`}>
        <Swiper
          ref={node => { if (node) this.swiper = node.swiper }}
          rebuildOnUpdate
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          }}
          keyboard={true}
          paginationClickable
          slidesPerView={1}
          spaceBetween={0}
          loop
          speed={500}
          autoplay={autoplay === 'yes'}
          initialSlide={initialSlide}
        >
          {list.map((slide, idx) =>
            <figure className={`gallery-slide ${slide.theme}`} key={idx}>
              <Img src={slide.image} variant="fullscreen" />

              <div className="slide-bg" style={{ backgroundImage: `url(${imageSrc(slide.image, 'fullscreen')})` }} />
              {slide.link &&
                <a href={slide.link}>

                  {slide.showCaption &&
                    <figcaption>
                      <h3>{slide.title}</h3>
                      {slide.description &&
                        <p>{slide.description}</p>
                      }
                    </figcaption>
                  }
                </a>
              }
              {!slide.link &&
                <div>
                  {slide.showCaption &&
                    <figcaption>
                      <h3>{slide.title}</h3>
                      {slide.description &&
                        <p>{slide.description}</p>
                      }
                    </figcaption>
                  }
                </div>
              }
            </figure>
          )}
        </Swiper>

        <button onClick={() => this.goNext()} className="swiper-button-next icon-arrow-right" />
        <button onClick={() => this.goPrev()} className="swiper-button-prev icon-arrow-left" />

        {onClose &&
          <button onClick={onClose} className="swiper-close" />
        }
      </div>
    );
  }
}

class Gallery extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) {
        this.handleClose();
      }
    }, false);
  }

  handleChange(swiper, slides) {
    const currentSlide = slides[swiper.realIndex];
    window.history.replaceState(null, null, currentSlide.url);
  }

  handleClose() {
    if (document.referrer !== '' && document.referrer !== window.location.href) {
      window.history.back();
    } else {
      window.location.href = this.props.backUrl;
    }
  }

  render() {
    const { initialSlide, list } = this.props;

    return (
      <div className="gallery-page">
        <SwiperGallery
          list={list}
          initialSlide={initialSlide}
          onChange={(swiper, slides) => this.handleChange(swiper, slides)}
          onClose={() => this.handleClose()}
        />
      </div>
    );
  }
}

export default Gallery;
