import React from 'react';
import PropTypes from 'prop-types';
import { Container, List, TextInput } from '@raketa-cms/raketa-cms';

const StatsWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="stats">
      <div className="container">
        <div className="grid">
          <div className="shift-2">
            <dl>
              {list.map((item, idx) =>
                <div key={idx}>
                  <dt>{item.title}</dt>
                  <dd>{item.data}</dd>
                </div>
              )}
            </dl>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

StatsWidget.title = 'Stats';
StatsWidget.category = 'Highlights';

StatsWidget.propTypes = {
  list: PropTypes.array,
  containerSettings: PropTypes.object.isRequired,
};

StatsWidget.defaults = {
  list: [
    { title: 'Total hours spent', data: '367' },
    { title: 'Project photos taken', data: '120' },
    { title: 'Awards won', data: '7' },
    { title: 'Budget', data: '$1,764,000' }
  ]
};

const StatsItem = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Title"
      onChange={value => onChangeItem('title', value)}
      value={settings.title}
    />

    <TextInput
      label="Data"
      onChange={value => onChangeItem('data', value)}
      value={settings.data}
    />
  </div>
);

StatsItem.defaultProps = {
  settings: {},
};

StatsItem.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

StatsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) =>
        <StatsItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default StatsWidget;
