import React from 'react';
import PropTypes from 'prop-types';
import { Container, Img } from '@raketa-cms/raketa-cms';

const SpotlightImage = ({ image, imageVisibility }) => (
  <div className="image-wrapper">
    {imageVisibility === 'without_image' ? null : <Img src={image} variant="fixed_image" />}
  </div>
);

const SpotlightContent = ({ title, description, btnPrimaryLabel, btnPrimaryLink, btnSecondaryLabel, btnSecondaryLink }) => (
  <div className="spotlight-content-wrapper description">
    <div className="spotlight-content">
      <h2>{title}</h2>
      <div dangerouslySetInnerHTML={{ __html: description }} />

      <div className="cta-wrapper">
        {(btnPrimaryLabel && btnPrimaryLink) &&
          <a href={btnPrimaryLink} className="btn-primary">{btnPrimaryLabel}</a>
        }
        {(btnSecondaryLabel && btnSecondaryLink) &&
          <a href={btnSecondaryLink} className="btn-secondary">{btnSecondaryLabel}</a>
        }
      </div>
    </div>
  </div>
);

const SpotlightWidget = ({
  direction,
  image,
  title,
  description,
  btnPrimaryLabel,
  btnPrimaryLink,
  btnSecondaryLabel,
  btnSecondaryLink,
  imageVisibility,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
      <div className="spotlight">
        <div className={`inner ${direction === 'right' ? 'reverse' : ''}`}>
          <SpotlightContent
            title={title}
            description={description}
            btnPrimaryLabel={btnPrimaryLabel}
            btnPrimaryLink={btnPrimaryLink}
            btnSecondaryLabel={btnSecondaryLabel}
            btnSecondaryLink={btnSecondaryLink}
          />

          <SpotlightImage image={image} imageVisibility={imageVisibility} />
        </div>
      </div>
  </Container>
);

SpotlightWidget.title = 'Spotlight';
SpotlightWidget.category = 'Highlights';

SpotlightWidget.defaultProps = {
  description: '',
  btnSecondaryLabel: '',
  btnSecondaryLink: '',
};

SpotlightWidget.propTypes = {
  direction: PropTypes.string.isRequired,
  image: PropTypes.any.isRequired,
  description: PropTypes.string,
  btnPrimaryLabel: PropTypes.string.isRequired,
  btnPrimaryLink: PropTypes.string.isRequired,
  imageVisibility: PropTypes.string.isRequired,
  btnSecondaryLabel: PropTypes.string,
  btnSecondaryLink: PropTypes.string,
  containerSettings: PropTypes.object.isRequired,
};

SpotlightWidget.dialogSize = 'large';

SpotlightWidget.defaults = {
  direction: 'left',
  image: 'http://via.placeholder.com/690x460',
  title: 'Spotlight title',
  description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic voluptatibus ratione ab nisi facere voluptas unde recusandae, dolorem quis blanditiis odit similique voluptate. Nostrum praesentium expedita vero, culpa eum perferendis!',
  btnPrimaryLabel: 'Call to action',
  btnPrimaryLink: '#',
  btnSecondaryLabel: 'Call to action',
  btnSecondaryLink: '#',
  imageVisibility: 'with_image',
};

SpotlightWidget.adminFields = {
  direction: { type: 'select', options: [['left', 'Left'], ['right', 'Right']] },
  image: { type: 'image' },
  imageVisibility: { type: 'select', options: [['with_image', 'With Image'], ['without_image', 'Without Image']] },
  title: { type: 'text' },
  description: { type: 'rich' },
  btnPrimaryLink: { type: 'text', label: 'Primary Link' },
  btnPrimaryLabel: { type: 'text', label: 'Primary Label' },
  btnSecondaryLink: { type: 'text', label: 'Secondary Link' },
  btnSecondaryLabel: { type: 'text', label: 'Secondary Label' },
};

export default SpotlightWidget;
