import React from 'react';
import PropTypes from 'prop-types';
import { Container, Img, List, TextInput, ImagePicker, SelectMenu, RichText } from '@raketa-cms/raketa-cms';

const CardsWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="cards">
      <div className="container">
        <div className="grid">
          {list.map((card, idx) => (
            <div className="third" key={idx}>
              <div className={`card ${card.cardBackground === 'light' ? '' : 'dark' }`}>
                <a href={card.link} className="card-image">
                  <Img src={card.image} variant="card_horizontal" />
                </a>

                <div className="card-content">
                  <h3><a href={card.link}>{card.title}</a></h3>
                  {card.description &&
                    <div className="text" dangerouslySetInnerHTML={{ __html: card.description }} />
                  }
                  {(card.link && card.linkLabel) &&
                    <a href={card.link} className="btn-text">{card.linkLabel}</a>
                  }
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

CardsWidget.title = 'Cards';
CardsWidget.category = 'Lists';

CardsWidget.dialogSize = 'large';

CardsWidget.propTypes = {
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

CardsWidget.defaults = {
  list: [
    { image: 'http://via.placeholder.com/490x370', cardBackground: 'light', title: 'Card title', description: '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, necessitatibus repellat soluta quis numquam.</p>', linkLabel: 'Call to action', link: '#' },
    { image: 'http://via.placeholder.com/490x370', cardBackground: 'light', title: 'Card title', description: '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, necessitatibus repellat soluta quis numquam.</p>', linkLabel: 'Call to action', link: '#' },
    { image: 'http://via.placeholder.com/490x370', cardBackground: 'light', title: 'Card title', description: '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, necessitatibus repellat soluta quis numquam.</p>', linkLabel: 'Call to action', link: '#' }
  ]
};

const CardsItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      onChange={value => onChangeItem('image', value)}
      value={settings.image}
    />

    <SelectMenu
      label="Card Background"
      options={[['light', 'Light'], ['dark', 'Dark']]}
      value={settings.cardBackground}
      onChange={value => onChangeItem('cardBackground', value)}
    />

    <TextInput
      label="Title"
      onChange={value => onChangeItem('title', value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={value => onChangeItem('description', value)}
      value={settings.description}
    />

    <TextInput
      label="Link Label"
      onChange={value => onChangeItem('linkLabel', value)}
      value={settings.linkLabel}
    />

    <TextInput
      label="Link"
      onChange={value => onChangeItem('link', value)}
      value={settings.link}
    />
  </div>
);

CardsItem.defaultProps = {
  settings: {},
};

CardsItem.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

CardsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) =>
        <CardsItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default CardsWidget;
