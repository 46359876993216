import React from 'react';
import { ImageInput, MediaManager } from '@raketa-cms/raketa-cms';

const ImagePicker = (props) => (
  <div>
    <ImageInput mediaManager={(new MediaManager('/'))} {...props} />
  </div>
);

export default ImagePicker;

