import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';

const QuoteWidget = ({ text, author, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="grid">
        <div className="quote">
          <blockquote>
            <p>{text}</p>
            <span className="author">{author}</span>
          </blockquote>
        </div>
      </div>
    </div>
  </Container>
);

QuoteWidget.title = 'Quote';
QuoteWidget.category = 'General';

QuoteWidget.propTypes = {
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

QuoteWidget.defaults = {
  text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum debitis, est!Asperiores ducimus animi impedit deleniti fuga amet iusto eveniet.',
  author: 'John Doe, CFO',
  containerSettings: {},
};

QuoteWidget.adminFields = {
  text: { type: 'textarea' },
  author: { type: 'text' },
};

export default QuoteWidget;