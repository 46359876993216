import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';
import { Parallax } from 'react-scroll-parallax';

import ParallaxImage from '../ParallaxImage';

const LlSectionWidget = ({ link, title, text, parallax_square_image, parallax_portrait_left, containerSettings }) => (
  <Container settings={containerSettings} className="parallax">
    <a href={link} className="parallax-box ll-section">
      <div className="square-image-mask">
        <Parallax
          offsetXMin={-130}
          offsetXMax={150}
          slowerScrollRate
          tag="figure"
        >
          <div style={{ width: '1900px', height: '1200px', backgroundColor: 'rgba(0,0,0,.8)' }} />
        </Parallax>
      </div>

      <div className="square-image">
        <Parallax
          offsetYMin={-50}
          offsetYMax={50}
          slowerScrollRate
          tag="figure"
        >
          <ParallaxImage src={parallax_square_image} variant="parallax_square_image" />
        </Parallax>
      </div>

      <div className="portrait-image-mask">
        <Parallax
          offsetXMin={400}
          offsetXMax={-100}
          slowerScrollRate
          tag="figure"
        >
          <div style={{ width: '1600px', height: '900px', backgroundColor: 'rgba(242,182,50,.6)' }} />
        </Parallax>
      </div>

      <div className="portrait-image">
        <Parallax
          offsetYMin={-50}
          offsetYMax={50}
          slowerScrollRate
          tag="figure"
        >
          <ParallaxImage src={parallax_portrait_left} variant="parallax_portrait_left" />
        </Parallax>
      </div>

      <div className="letter-bg"></div>

      <Parallax
        className="title"
        offsetYMin={-10}
        offsetYMax={1000}
        tag="div"
      >
        <h1>{title}</h1>
      </Parallax>

      <Parallax
        className="letter"
        offsetYMin={-10}
        offsetYMax={1000}
        tag="div"
      >
        <div className="letter-wrapper ll">
          <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMThweCIgaGVpZ2h0PSIyN3B4IiB2aWV3Qm94PSIwIDAgMTggMjciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ3LjEgKDQ1NDIyKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5sbDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJsbCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzLjAwMDAwMCwgLTEuMDAwMDAwKSI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjQ0IiBoZWlnaHQ9IjI4Ij48L3JlY3Q+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTkuMzcsMjcuNjQwOTcwMiBMMTMsMjcuNjQwOTcwMiBMMTMsMSBMMTkuMzcsMSBMMTkuMzcsMjcuNjQwOTcwMiBaIE0zMC40MiwyNy42NDA5NzAyIEwyNC4wNSwyNy42NDA5NzAyIEwyNC4wNSwxLjE5MDA3NzM5IEwzMC40MiwxLjE5MDA3NzM5IEwzMC40MiwyNy42NDA5NzAyIFoiIGlkPSJwYXJhbGxheC1zdHVkaW8iIGZpbGw9IiNGRkZGRkYiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=" />
        </div>
      </Parallax>

      <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
    </a>
  </Container>
);

LlSectionWidget.title = 'LL Section';
LlSectionWidget.category = 'Parallax Homepage';

LlSectionWidget.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  parallax_square_image: PropTypes.any.isRequired,
  parallax_portrait_left: PropTypes.any.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

LlSectionWidget.defaults = {
  link: '#',
  title: 'Title',
  text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quibusdam eius voluptate repudiandae odio aut, ea voluptas. In consectetur praesentium minus molestiae fuga hic? Tempore consequuntur debitis maiores laudantium aliquid.',
  parallax_square_image: 'http://via.placeholder.com/1120x955',
  parallax_portrait_left: 'http://via.placeholder.com/560x630',
  containerSettings: {},
};

LlSectionWidget.adminFields = {
  link: { type: 'text' },
  parallax_square_image: { type: 'image' },
  parallax_portrait_left: { type: 'image' },
  title: { type: 'text' },
  text: { type: 'rich' },
};


export default LlSectionWidget;