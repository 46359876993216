import React from 'react';
import Swiper from 'react-id-swiper';
import { imageSrc } from '@raketa-cms/raketa-cms';

class SwiperGallery extends React.Component {
  componentDidMount() {
    const { list, onChange } = this.props;
    if (onChange) this.swiper.on('slideChange', () => onChange(this.swiper, list));
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext()
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev()
  }

  render() {
    const { autoplay, zoom, list, initialSlide, onClose } = this.props;

    return (
      <div className={`gallery ${zoom ? 'has-zoom' : ''}`}>
        <Swiper
          ref={node => { if (node) this.swiper = node.swiper }}
          rebuildOnUpdate
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          }}
          keyboard={true}
          paginationClickable
          slidesPerView={1}
          spaceBetween={0}
          loop
          speed={500}
          autoplay={autoplay === 'yes'}
          initialSlide={initialSlide}
        >
          {list.map((slide, idx) =>
            <figure
              className={`gallery-slide ${slide.theme}`}
              key={idx}
              style={{ backgroundImage: `url(${imageSrc(slide.image, 'fullscreen')})` }}
            >
              {slide.link &&
                <a href={slide.link}>

                  <figcaption>
                    <h3>{sxlide.title}</h3>
                    {slide.description &&
                      <p>{slide.description}</p>
                    }
                  </figcaption>
                </a>
              }
              {!slide.link &&
                <div>
                  <figcaption>
                    <h3>{slide.title}</h3>
                    {slide.description &&
                      <p>{slide.description}</p>
                    }
                  </figcaption>
                </div>
              }
            </figure>
          )}
        </Swiper>

        <button onClick={() => this.goNext()} className="swiper-button-next icon-arrow-right" />
        <button onClick={() => this.goPrev()} className="swiper-button-prev icon-arrow-left" />

        {onClose &&
          <button onClick={onClose} className="swiper-close" />
        }
      </div>
    );
  }
}

SwiperGallery.defaultProps = {
  initialSlide: 0,
  zoom: true,
};

export default SwiperGallery;
