import React from 'react';
import PropTypes from 'prop-types';
import { Container, Img, List, TextInput, TextArea, ImagePicker, SelectMenu } from '@raketa-cms/raketa-cms';

const ProjectPreviewPlain = ({
  title,
  description,
  image,
  list,
  overlay,
  btnLink,
  btnLabel,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="project-preview">
      <div className="overlay" style={{ backgroundColor: `rgba(0, 0, 0, ${overlay})` }} ></div>
      <Img src={image} variant="lead" />
      <Img src={image} variant="square" />

      <div className="project-preview-content-wrapper">
        <div className="container project-preview-content">
          <h1>{title}</h1>
          <p>{description}</p>

          <ul className="project-preview-stats">
            {list.map((stat, idx) => (
              <li key={idx}>
                <h4>{stat.label}</h4>
                <span>{stat.value}</span>
              </li>
            ))}
          </ul>

          {(btnLabel && btnLink) &&
            <div className="cta-wrapper">
              <a href={btnLink} className="btn-secondary invert">{btnLabel}</a>
            </div>
          }
        </div>
      </div>
    </div>
  </Container>
);

ProjectPreviewPlain.title = 'Project Preview (plain)';
ProjectPreviewPlain.category = 'Highlights';

ProjectPreviewPlain.defaultProps = {
  btnLink: '',
  btnLabel: '',
};

ProjectPreviewPlain.propTypes = {
  overlay: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.any.isRequired,
  btnLink: PropTypes.string,
  btnLabel: PropTypes.string,
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

ProjectPreviewPlain.defaults = {
  title: 'Project title',
  description: 'More project details here',
  image: 'http://via.placeholder.com/1920x800',
  btnLink: '#',
  btnLabel: 'Learn more',
  overlay: 0.2,
  list: [
    { label: 'GB', value: 75 },
    { label: 'Hours', value: 145 },
    { label: 'Team', value: 4 },
    { label: 'Awards', value: 13 },
  ],
};

const ProjectPreviewPlainItem = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Label"
      onChange={value => onChangeItem('label', value)}
      value={settings.label}
    />

    <TextInput
      label="Value"
      onChange={value => onChangeItem('value', value)}
      value={settings.value}
    />
  </div>
);

ProjectPreviewPlainItem.defaultProps = {
  settings: {},
};

ProjectPreviewPlainItem.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

ProjectPreviewPlain.adminFields = (items, onChange, settings) => (
  <div>
    <TextInput
      label="Title"
      onChange={value => onChange('title', value)}
      value={settings.title}
    />

    <TextArea
      label="Description"
      onChange={value => onChange('description', value)}
      value={settings.description}
    />

    <ImagePicker
      onChange={value => onChange('image', value)}
      value={settings.image}
    />

    <TextInput
      label="Button Label"
      onChange={value => onChange('btnLabel', value)}
      value={settings.btnLabel}
    />

    <TextInput
      label="Button Link"
      onChange={value => onChange('btnLink', value)}
      value={settings.btnLink}
    />

    <SelectMenu
      label="Overlay"
      options={[[0, 'No Overlay'], [0.1, '10%'], [0.2, '20%'], [0.3, '30%'], [0.4, '40%'], [0.5, '50%'], [0.6, '60%'], [0.7, '70%'], [0.8, '80%']]}
      value={settings.overlay}
      onChange={value => onChange('overlay', value)}
    />

    <List
      listItem={(settings, onChangeItem) =>
        <ProjectPreviewPlainItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default ProjectPreviewPlain;
