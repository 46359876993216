import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import { Container, List, ImagePicker, TextInput, TextArea, SelectMenu, imageSrc } from '@raketa-cms/raketa-cms';

class SwiperGallery extends React.Component {
  componentDidMount() {
    const { list, onChange } = this.props;
    if (onChange) this.swiper.on('slideChange', () => onChange(this.swiper, list));
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext()
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev()
  }

  render() {
    const { autoplay, zoom, list, initialSlide, onClose } = this.props;

    return (
      <div className={`full-screen-slider ${zoom ? 'has-zoom' : ''}`}>
        <Swiper
          ref={node => { if (node) this.swiper = node.swiper }}
          rebuildOnUpdate
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          }}
          keyboard={true}
          paginationClickable
          slidesPerView={1}
          spaceBetween={0}
          loop
          speed={500}
          autoplay={autoplay === 'yes'}
          initialSlide={initialSlide}
        >
          {list.map((slide, idx) =>
            <a key={slide.id} href={slide.link} className={`full-screen-slide ${slide.theme}`} style={{ backgroundImage: `url('${imageSrc(slide.image, 'fullscreen')}')` }}>
              <div className="full-screen-description">
                <h3>{slide.title}</h3>
                {slide.description &&
                  <p>{slide.description}</p>
                }
              </div>
            </a>
          )}
        </Swiper>

        <button onClick={() => this.goNext()} className="swiper-button-next icon-arrow-right" />
        <button onClick={() => this.goPrev()} className="swiper-button-prev icon-arrow-left" />

        {onClose &&
          <button onClick={onClose} className="swiper-close" />
        }
      </div>
    );
  }
}

SwiperGallery.defaultProps = {
  initialSlide: 0,
  zoom: true,
};

const LargeSliderWidget = ({ initialSlide, autoplay, zoom, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <SwiperGallery list={list} zoom={zoom === 'yes'} autoplay={autoplay} initialSlide={initialSlide} />
  </Container>
);

LargeSliderWidget.defaultProps = {
  containerSettings: {},
};

LargeSliderWidget.title = 'Large Image Slider';
LargeSliderWidget.category = 'Media';

LargeSliderWidget.propTypes = {
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

LargeSliderWidget.defaults = {
  autoplay: 'no',
  zoom: 'no',
  list: [
    { id: 1, theme: 'theme-black-transparent', image: 'http://placehold.it/1920x1080', link: '#', title: 'Slide 1', description: 'Lorem ipsum dolor sit nostrum necessitatibus ipsum dolor' },
    { id: 2, theme: 'theme-black-transparent', image: 'http://placehold.it/1920x1080', link: '#', title: 'Slide 2', description: 'Lorem ipsum dolor sit nostrum necessitatibus ipsum dolor' },
    { id: 3, theme: 'theme-black-transparent', image: 'http://placehold.it/1920x1080', link: '#', title: 'Slide 3', description: 'Lorem ipsum dolor sit nostrum necessitatibus ipsum dolor' },
  ],
  containerSettings: {},
};

const SLIDE_THEMES = [
  ['theme-white-transparent', 'White transparent'],
  ['theme-black-transparent', 'Black transparent'],
  ['theme-white', 'White'],
  ['theme-black', 'Black'],
];

const SingleImageSliderItem = ({ settings, onChangeItem }) => (
  <div>
    <SelectMenu
      label="Slide Theme"
      options={SLIDE_THEMES}
      value={settings.theme}
      onChange={value => onChangeItem('theme', value)}
    />

    <ImagePicker
      onChange={value => onChangeItem('image', value)}
      value={settings.image}
    />

    <TextInput
      label="Link"
      onChange={value => onChangeItem('link', value)}
      value={settings.link}
    />

    <TextInput
      label="Title"
      onChange={value => onChangeItem('title', value)}
      value={settings.title}
    />

    <TextArea
      label="Description"
      onChange={value => onChangeItem('description', value)}
      value={settings.description}
    />
  </div>
);

SingleImageSliderItem.defaultProps = {
  settings: {},
};

SingleImageSliderItem.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

LargeSliderWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Autoplay"
      options={[['no', 'No'], ['yes', 'Yes']]}
      value={settings.autoplay}
      onChange={value => onChange('autoplay', value)}
    />
    <SelectMenu
      label="Zoom on hover"
      options={[['no', 'No'], ['yes', 'Yes']]}
      value={settings.zoom}
      onChange={value => onChange('zoom', value)}
    />

    <List
      listItem={(settings, onChangeItem) =>
        <SingleImageSliderItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default LargeSliderWidget;
