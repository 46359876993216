import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxProvider } from 'react-scroll-parallax';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';

import LIBRARY from './widgets';

const PageRender = ({ page, logo, logo_dark, header_menu, footer_menu, social_facebook, social_linkedin, social_instagram, contact_subjects }) => (
  <div>
    <SiteHeader
      menu={header_menu}
      logo={logo}
      stickyLogo={page.theme === 'dark' ? logo_dark : logo}
      theme={page.theme === 'dark' ? 'light' : 'dark'}
    />

    <div className="page-wrapper">
      <ParallaxProvider>
        {page.widgets.map((widget, idx) =>
          React.createElement(
            LIBRARY[widget.component],
            Object.assign({}, { key: idx }, widget.settings)))}
      </ParallaxProvider>
    </div>

    <SiteFooter logo={logo} menu={footer_menu} social_facebook={social_facebook} social_linkedin={social_linkedin} social_instagram={social_instagram} contact_subjects={contact_subjects} />
  </div>
);

PageRender.propTypes = {
  page: PropTypes.object.isRequired,
};

export default PageRender;
