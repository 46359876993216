import React from 'react';
import PropTypes from 'prop-types';
import { Container, List, TextInput, TextArea, SelectMenu } from '@raketa-cms/raketa-cms';

const FeaturesWidget = ({ list, showCounter, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="features">
      <div className="container">
        <div className="grid">
          {list.map((item, idx) =>
            <div className="third" key={idx}>
              <div className="feature">
                <h3>{showCounter === 'yes' ? <span className="feature-dropcap">{idx + 1}. </span>: null}{item.service}</h3>
                <p>{item.description}</p>
                <a href={item.link} className="btn-text">{item.linkLabel}</a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </Container>
);

FeaturesWidget.title = 'Features';
FeaturesWidget.category = 'Highlights';

FeaturesWidget.propTypes = {
  list: PropTypes.array.isRequired,
  showCounter: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

FeaturesWidget.defaults = {
  showCounter: 'no',
  list: [
    { service: 'Service', description: 'Excepturi repellendus est, nobis debitis placeat ipsum optio animi tempora inventore ea aliquid quae eaque facilis. Perferendis, consequatur.', linkLabel: 'Learn more', link: '#' },
    { service: 'Service', description: 'Excepturi repellendus est, nobis debitis placeat ipsum optio animi tempora inventore ea aliquid quae eaque facilis. Perferendis, consequatur.', linkLabel: 'Learn more', link: '#' },
    { service: 'Service', description: 'Excepturi repellendus est, nobis debitis placeat ipsum optio animi tempora inventore ea aliquid quae eaque facilis. Perferendis, consequatur.', linkLabel: 'Learn more', link: '#' },
    { service: 'Service', description: 'Excepturi repellendus est, nobis debitis placeat ipsum optio animi tempora inventore ea aliquid quae eaque facilis. Perferendis, consequatur.', linkLabel: 'Learn more', link: '#' },
    { service: 'Service', description: 'Excepturi repellendus est, nobis debitis placeat ipsum optio animi tempora inventore ea aliquid quae eaque facilis. Perferendis, consequatur.', linkLabel: 'Learn more', link: '#' },
    { service: 'Service', description: 'Excepturi repellendus est, nobis debitis placeat ipsum optio animi tempora inventore ea aliquid quae eaque facilis. Perferendis, consequatur.', linkLabel: 'Learn more', link: '#' }
  ]
};

const FeaturesItem = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Service"
      onChange={value => onChangeItem('service', value)}
      value={settings.service}
    />

    <TextArea
      label="Description"
      onChange={value => onChangeItem('description', value)}
      value={settings.description}
    />

    <TextInput
      label="Link Label"
      onChange={value => onChangeItem('linkLabel', value)}
      value={settings.linkLabel}
    />

    <TextInput
      label="Link Label"
      onChange={value => onChangeItem('link', value)}
      value={settings.link}
    />
  </div>
);

FeaturesItem.defaultProps = {
  settings: {},
};

FeaturesItem.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

FeaturesWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Show Counter"
      options={[['no', 'No'], ['yes', 'Yes']]}
      value={settings.showCounter}
      onChange={value => onChange('showCounter', value)}
    />

    <List
      listItem={(settings, onChangeItem) =>
        <FeaturesItem settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default FeaturesWidget;
