import React from 'react';
import PropTypes from 'prop-types';
import { Img } from '@raketa-cms/raketa-cms';

class ParallaxImage extends React.Component {
  static contextTypes = {
    parallaxController: PropTypes.object.isRequired,
  };

  handleLoad = () => {
    // updates cached values after image dimensions have loaded
    this.context.parallaxController.update();
  };

  render() {
    const { src, variant } = this.props;

    return <Img src={src} variant={variant} onLoad={this.handleLoad} />;
  }
}

export default ParallaxImage;
