import React from 'react';
import Cocoen from 'cocoen';
import PropTypes from 'prop-types';
import { Container, Img } from '@raketa-cms/raketa-cms';

class BeforeAfterWidget extends React.Component {
  componentDidMount() {
    new Cocoen(this.slider);
  }

  setSlider(slider) {
    this.slider = slider;
  }

  render() {
    const { before_image, after_image, variant } = this.props;

    return (
      <div className="slider-before-after">
        <div className={variant === 'inset' ? 'container' : ''}>
          <div className="cocoen" ref={slider => this.setSlider(slider)}>
            <Img src={before_image} className="slider-img after-img" variant="fullscreen" />
            <Img src={after_image} className="slider-img before-img" variant="fullscreen" />
          </div>
        </div>
      </div>
    );
  }
}

BeforeAfterWidget.title = 'Before After';
BeforeAfterWidget.category = 'Media';

BeforeAfterWidget.propTypes = {
  before_image: PropTypes.any.isRequired,
  after_image: PropTypes.any.isRequired,
  variant: PropTypes.string.isRequired,
};

BeforeAfterWidget.defaults = {
  before_image: 'http://via.placeholder.com/1920x1080',
  after_image: 'http://via.placeholder.com/1920x1080',
  variant: 'full-width',
};

BeforeAfterWidget.adminFields = {
  variant: { type: 'select', options: [['full-width', 'Full Width'], ['inset', 'Inset']] },
  before_image: { type: 'image' },
  after_image: { type: 'image' },
};

export default BeforeAfterWidget;
