import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';

const CtaWidget = ({
  title,
  description,
  btnPrimaryLabel,
  btnPrimaryLink,
  btnSecondaryLabel,
  btnSecondaryLink,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="cta">
      <div className="container">
        <div className="grid">
          <div className="shift-2">
            <h2>{title}</h2>
            <p>{description}</p>
            <div className="cta-wrapper">
              {(btnPrimaryLabel && btnPrimaryLink) &&
                <a href={btnPrimaryLink} className="btn-primary">{btnPrimaryLabel}</a>
              }
              {(btnSecondaryLink && btnSecondaryLabel) &&
                <a href={btnSecondaryLink} className="btn-secondary">{btnSecondaryLabel}</a>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

CtaWidget.title = 'Call to action';
CtaWidget.category = 'Highlights';

CtaWidget.defaultProps = {
  btnSecondaryLabel: '',
  btnSecondaryLink: '',
};

CtaWidget.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  btnPrimaryLabel: PropTypes.string.isRequired,
  btnPrimaryLink: PropTypes.string.isRequired,
  btnSecondaryLabel: PropTypes.string,
  btnSecondaryLink: PropTypes.string,
};

CtaWidget.defaults = {
  title: 'Call to action block',
  description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam aspernatur, nulla provident dolorem consectetur delectus repellat corrupti vero minus dignissimos officiis dolores sint quas voluptatum voluptatem ullam ad error iusto.',
  btnPrimaryLabel: 'Call to action',
  btnPrimaryLink: '#',
  btnSecondaryLabel: 'Call to action',
  btnSecondaryLink: '#',
  containerSettings: {},
};

CtaWidget.adminFields = {
  title: { type: 'text' },
  description: { type: 'textarea' },
  btnPrimaryLabel: { type: 'text' },
  btnPrimaryLink: { type: 'text' },
  btnSecondaryLabel: { type: 'text' },
  btnSecondaryLink: { type: 'text' },
};

export default CtaWidget;
