import React from 'react';

const noop = e => e.preventDefault();

function stopBodyScrolling(bool) {
  if (bool === true) {
    document.body.classList.add('no-scroll');
    document.body.addEventListener('touchmove', noop, false);
  } else {
    document.body.classList.remove('no-scroll');
    document.body.removeEventListener('touchmove', noop, false);
  }
}

class Modal extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', (e) => {
      if (this.modal) {
        this.handleEscKey(e);
      }
    }, false);
  }

  componentWillReceiveProps(newProps) {
    stopBodyScrolling(newProps.open);
  }

  handleEscKey(e) {
    if (e.keyCode === 27) {
      this.props.onClose();
    }
  }

  render() {
    const { title, open, onClose, children, className, titleAlign } = this.props;

    return (
      <div className={`cg-modal ${open === true ? 'modal-show' : ''}`} ref={(modal) => { this.modal = modal; }}>
        <div className={`modal-cnt ${className !== '' ? className : ''}`} style={{ maxWidth: '600px' }}>
          {title !== '' ?
            <div className="modal-title-wrapper">
              <h3 className={`modal-title ${titleAlign === 'left' ? 'text-left' : 'text-center'}`}>{title}</h3>
            </div> : ''
          }

          <button className="icon-close close" onClick={() => onClose()}></button>

          {children}
        </div>

        <div className="modal-overlay" onClick={() => onClose()} />
      </div>
    );
  }
}

Modal.defaultProps = {
  className: '',
  titleAlign: 'left',
};

export default Modal;
